var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"invoice-preview-card"},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('h2',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(_vm.$t("customer_information.information_content.create_title"))),_c('br')]),_c('b-button',{staticClass:"d-flex align-items-center",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$router.push({ name: 'contenu-informationnel-list' })}}},[_c('i',{staticClass:"las la-arrow-left mr-50"}),_c('span',[_vm._v(_vm._s(_vm.$t("button.back_to_list")))])])],1)]),_c('b-card',[_c('validation-observer',{ref:"formRules"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t(
                'customer_information.information_content.create_input_title'
              ),"label-cols-md":"12","label-for":"title"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t( "customer_information.information_content.create_input_title" ))+" "),_c('RequiredStar')],1)]},proxy:true}])},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                    'customer_information.information_content.create_input_title_placeholder'
                  ),"type":"text"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('customer_information.information_content.create_input_type'),"label-cols-md":"12","label-for":"categories"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t( "customer_information.information_content.create_input_type" ))+" "),_c('RequiredStar')],1)]},proxy:true}])},[_c('v-select',{attrs:{"id":"categories","placeholder":_vm.$t(
                  'customer_information.information_content.create_input_type_placeholder'
                ),"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"label","reduce":function (option) { return option.value; },"options":_vm.listTypePosts},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t(
                'customer_information.information_content.create_input_sector'
              ),"label-cols-md":"12","label-for":"secteur"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t( "customer_information.information_content.create_input_sector" ))+" "),_c('RequiredStar')],1)]},proxy:true}])},[_c('v-select',{attrs:{"id":"roles","placeholder":_vm.$t(
                  'customer_information.information_content.create_input_sector_placeholder'
                ),"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","reduce":function (option) { return option.id; },"options":_vm.listSecteurs},model:{value:(_vm.form.secteur_id),callback:function ($$v) {_vm.$set(_vm.form, "secteur_id", $$v)},expression:"form.secteur_id"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('customer_information.information_content.create_input_tags'),"label-cols-md":"12","label-for":"cover"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t( "customer_information.information_content.create_input_tags" ))+" "),_c('RequiredStar')],1)]},proxy:true}])},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-tags',{attrs:{"id":"tags","add-button-text":_vm.$t('button.add'),"type":"text","placeholder":_vm.$t(
                    'customer_information.information_content.create_input_tags_placeholder'
                  )},model:{value:(_vm.form.tags),callback:function ($$v) {_vm.$set(_vm.form, "tags", $$v)},expression:"form.tags"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t(
                'customer_information.information_content.create_input_cover'
              ),"label-cols-md":"12","label-for":"cover"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t( "customer_information.information_content.create_input_cover" ))+" "),_c('RequiredStar')],1)]},proxy:true}])},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"cover","accept":"image/*","placeholder":_vm.$t(
                    'customer_information.information_content.create_input_cover_placeholder'
                  ),"drop-placeholder":"Déposer le fichier ici..."},model:{value:(_vm.form.cover),callback:function ($$v) {_vm.$set(_vm.form, "cover", $$v)},expression:"form.cover"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t(
                'customer_information.information_content.create_input_status'
              ),"label-cols-md":"12","label-for":"cover"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t( "customer_information.information_content.create_input_status" ))+" "),_c('RequiredStar')],1)]},proxy:true}])},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"status","placeholder":_vm.$t(
                    'customer_information.information_content.create_input_status_placeholder'
                  ),"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"label","reduce":function (option) { return option.value; },"options":_vm.listPostsStatus},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t(
                'customer_information.information_content.create_input_description'
              ),"label-cols-md":"12","label-for":"description"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t( "customer_information.information_content.create_input_description" ))+" "),_c('RequiredStar')],1)]},proxy:true}])},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"mb-2 mb-lg-0",attrs:{"placeholder":_vm.$t(
                    'customer_information.information_content.create_input_description_placeholder'
                  )},model:{value:(_vm.form.short_description),callback:function ($$v) {_vm.$set(_vm.form, "short_description", $$v)},expression:"form.short_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.form.type == 'concours')?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('customer_information.information_content.create_input_url'),"label-cols-md":"12","label-for":"title"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t( "customer_information.information_content.create_input_url" ))+" "),_c('RequiredStar')],1)]},proxy:true}],null,false,3254529842)},[_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                  'customer_information.information_content.create_input_url_placeholder'
                ),"type":"url"},on:{"input":_vm.validateUrl},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}}),(_vm.isValidUrl == false)?_c('small',{staticClass:"text-danger"},[_vm._v(" Veuillez renseigner une url valide ")]):_vm._e()],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t(
                'customer_information.information_content.create_input_content'
              ),"label-cols-md":"12","label-for":"description"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t( "customer_information.information_content.create_input_content" ))+" "),_c('RequiredStar')],1)]},proxy:true}])},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('vue-editor',{attrs:{"placeholder":_vm.$t(
                    'customer_information.information_content.create_input_content_placeholder'
                  ),"editor-toolbar":_vm.customToolbar},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t(
                'customer_information.information_content.create_input_attaches_files'
              ),"label-cols-md":"12","label-for":"cover"}},[_c('b-form-file',{attrs:{"id":"attached_file","multiple":"","placeholder":_vm.$t(
                  'customer_information.information_content.create_input_cover_placeholder'
                ),"drop-placeholder":"Déposer le(s) fichier(s) ici..."},model:{value:(_vm.form.attached_files),callback:function ($$v) {_vm.$set(_vm.form, "attached_files", $$v)},expression:"form.attached_files"}})],1)],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isCreatingPost},on:{"click":function($event){return _vm.createPost()}}},[(!_vm.isCreatingPost)?_c('feather-icon',{attrs:{"icon":"SaveIcon"}}):_c('b-spinner',{attrs:{"small":""}}),_vm._v("   "+_vm._s(_vm.$t("customer_information.information_content.create_form_button"))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }