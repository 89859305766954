import appConfigurationService from '@/services/http/app-configuration.service'

export default {
  namespaced: true,
  state: {
    user_log: false,
    passForgotAccess:false,
  },
  getters: {
    getter_user_log(state) {
      return state.user_log
    },
    getter_passForgotAccess(state) {
      return state.passForgotAccess
    },

  },
  mutations: {
    setter_user_log(state, data) {
      state.user_log = data
    },
    setter_passForgotAccess(state, payload) {
      state.passForgotAccess = payload;
    },
  },
  actions: {
    async fetchAppConfiguration({ rootState }, data) {
      const res = await appConfigurationService.fetchAppConfiguration(data)
      return res
    },
    async getAppConfiguration({ rootState }, data) {
      const res = await appConfigurationService.getAppConfiguration(data)
      return res
    },
    async addAppConfiguration({ rootState }, data) {
      const res = await appConfigurationService.addAppConfiguration(data)
      return res
    },
    async updateAppConfiguration({ rootState }, data) {
      const res = await appConfigurationService.updateAppConfiguration(data)
      return res
    },
    async removeAppConfiguration({ rootState }, data) {
      const res = await appConfigurationService.removeAppConfiguration(data)
      return res
    },
    async searchAppConfiguration({ rootState }, data) {
      const res = await appConfigurationService.searchAppConfiguration(data)
      return res
    },

  },
}
