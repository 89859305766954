import { BaseService } from './base.service'

export class AppConfigurationService extends BaseService {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super()
    }

    fetchAppConfiguration() {
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .get('/app-configurations')
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    getAppConfiguration(id) {
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .get(`/app-configurations/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    addAppConfiguration(data) {
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .post('/app-configurations', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    updateAppConfiguration(object) {
        // console.log('object::: ', object)
        const { id } = object
        const { value } = object.data
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .put(`/app-configurations/${id}`, { value })
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    removeAppConfiguration({ id }) {
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .delete(`/app-configurations/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    searchAppConfiguration(data) {
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .post('/app-configurations/search', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

}

export default new AppConfigurationService()
